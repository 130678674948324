export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";

export const UPDATE_USER = 'UPDATE_USER';

export const LOAD_PROJECTS = 'LOAD_PROJECTS';
export const CLEAR_PROJECTS = 'CLEAR_PROJECTS';
export const ADD_PROJECT = 'ADD_PROJECT';
export const PROJECT_FAIL = 'PROJECT_FAIL';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const SET_CURRENT = 'SET_CURRENT';
export const CLEAR_CURRENT = 'CLEAR_CURRENT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const FILTER_PROJECTS = 'FILTER_PROJECT';
export const CLEAR_FILTER = 'CLEAR_FILTER';