import axios from 'services/axiosConfig';

export const createProject = async (values) => {
  let request = axios.post('projects', values);

  return request.then((response) => {
    if (response.status === 201) {
      return response.data && response.data.data;
    }
  });
};
