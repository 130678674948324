import Loadable from 'react-loadable';
import loadingSpinner from './loading.js';

export const LandingPage = Loadable({
  loader: () => import('../views/LandingPage'),
  loading: loadingSpinner
});

export const LandingPage2 = Loadable({
  loader: () => import('../views/LandingPage2'),
  loading: loadingSpinner
});

export const Login = Loadable({
  loader: () => import('../views/Auth/login'),
  loading: loadingSpinner
});

export const Register = Loadable({
  loader: () => import('../views/Auth/register'),
  loading: loadingSpinner,
});

export const Confirm= Loadable({
  loader: () => import('../views/Auth/confirm'),
  loading: loadingSpinner,
});


export const Reset = Loadable({
  loader: () => import('../views/Auth/reset'),
  loading: loadingSpinner,
});

export const Recover = Loadable({
  loader: () => import('../views/Auth/recover'),
  loading: loadingSpinner,
});



export const Skills = Loadable({
  loader: () => import('../views/Auth/skills'),
  loading: loadingSpinner,
});

export const AdminDashBoard = Loadable({
  loader: () => import('../views/Admin/index'),
  loading: loadingSpinner,
});

export const TakersDashBoard = Loadable({
  loader: () => import('../views/Takers/index'),
  loading: loadingSpinner,
});

export const ModeratorDashBoard = Loadable({
  loader: () => import('../views/Moderators/index'),
  loading: loadingSpinner,
});

export const NotFound = Loadable({
  loader: () => import('../views/NotFound'),
  loading: loadingSpinner
});


// export const UserDashBoard = Loadable({
//   loader: () => import('../views/User/index'),
//   loading: loadingSpinner
// });

// export const VendorDashBoard = Loadable({
//   loader: () => import('../views/Vendor/index'),
//   loading: loadingSpinner
// });


