import React, {useReducer} from 'react';
import projectContext from './projectContext';
import projectReducer from './projectReducer';
import { createProject } from 'services/projects';
import {
  // ADD_PROJECT,
  // PROJECT_FAIL
  // LOAD_PROJECTS,
  // CLEAR_PROJECTS,
  // DELETE_PROJECT,
  // SET_CURRENT,
  // CLEAR_CURRENT,
  // UPDATE_PROJECT,
  // FILTER_PROJECTS,
  // CLEAR_FILTER
} from '../types';
import { toast } from 'react-toastify';



const ProjectState = (props) => {
  const initialState = {
    projects: [],
    currentproject: {},
    currentSet: false,
    filtered: null,
    projectError: null,
  };

  // eslint-disable-next-line
  const [state, dispatch] = useReducer(projectReducer, initialState);

  //Add single project
  const addProject = async (project) => {
    try {
      await createProject(project);
      toast.error('Project Added');
      // dispatch({
      //   type: ADD_PROJECT,
      //   payload: project,
      // });
    } catch (err) {
      toast.error('Project not Added');
      // dispatch({
      //   type: PROJECT_FAIL,
      //   payload: err.response.data.msg,
      // });
    }
  };

  //   //Load projects
  //   const loadprojects = async (userId) => {
  //     if (localStorage.token) {
  //       setAuthToken(localStorage.token);
  //     }

  //     try {
  //       const res = await Axios.get(`http://localhost:5000/api/project/${userId}`);
  //       dispatch({
  //         type: LOAD_projectS,
  //         payload: res.data.projects,
  //       });
  //     } catch (err) {
  //       console.log('get contaact failed');
  //       console.log('respinse', err.response);
  //       dispatch({
  //         type: project_FAIL,
  //         payload: err,
  //       });
  //     }
  //   };

  //   //Clear projects
  //   const clearprojects = () => {
  //     dispatch({
  //       type: CLEAR_projectS,
  //     });
  //   };

  //   //Delete project
  //   const deleteproject = async (id) => {
  //     try {
  //       const res = await Axios.delete('http://localhost:5000/api/project/' + id);
  //       dispatch({
  //         type: DELETE_project,
  //         payload: res.data.project,
  //       });
  //     } catch (err) {
  //       console.log('delete contaact failed');
  //       console.log('respinse', err.response);
  //       dispatch({
  //         type: project_FAIL,
  //         payload: err,
  //       });
  //     }
  //   };

  //   //set Current
  //   const setCurrent = (projectId) => {
  //     dispatch({
  //       type: SET_CURRENT,
  //       payload: projectId,
  //     });
  //   };

  //   //clear current
  //   const clearCurrent = () => {
  //     dispatch({
  //       type: CLEAR_CURRENT,
  //     });
  //   };

  //   //Update project
  //   const updateproject = async (id, project) => {
  //     if (localStorage.token) {
  //       setAuthToken(localStorage.token);
  //     }

  //     try {
  //       const res = await Axios.patch('http://localhost:5000/api/project/' + id, project);
  //       console.log(id, ' identifies: ', res.data.project);
  //       dispatch({
  //         type: UPDATE_project,
  //         payload: res.data.project,
  //       });
  //     } catch (err) {
  //       console.log('update contaact failed');
  //       console.log('respinse', err.response);
  //       dispatch({
  //         type: project_FAIL,
  //         payload: err,
  //       });
  //     }
  //   };

  //   //Filter projects
  //   const filterprojects = (keyword) => {
  //     dispatch({
  //       type: FILTER_projectS,
  //       payload: keyword,
  //     });
  //   };

  //   //Clear Filter
  //   const clearFilter = () => {
  //     dispatch({
  //       type: CLEAR_FILTER,
  //     });
  //   };

  return (
    <projectContext.Provider
      value={{
        projects: state.projects,
        addProject,
        // currentproject: state.currentproject,
        // currentSet: state.currentSet,
        // filtered: state.filtered,
        // projectError: state.projectError,
        // projectErrorFlag: state.projectErrorFlag,
        // deleteproject: deleteproject,
        // setCurrent: setCurrent,
        // clearCurrent: clearCurrent,
        // updateproject: updateproject,
        // filterprojects: filterprojects,
        // clearFilter: clearFilter,
        // loadprojects: loadprojects,
        // clearprojects: clearprojects,
      }}
    >
      {props.children}
    </projectContext.Provider>
  );
}

export default ProjectState;