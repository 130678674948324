/* eslint-disable no-unused-vars */
import axios from 'services/axiosConfig';

export const loginUser  = async (details) => {
  let request = axios.post('login', details);
  return request.then((response) => {
    if (response.status === 200) {
      return response && response;
    }
  });
};

export const updateUser = async (details) => {
  let request = axios.put('user', details);
  return request.then((response) => {
    if (response.status === 201) {
      return response && response;
    }
  });
};

