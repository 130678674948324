import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthContext from '../../context/auth/authContext';

export const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  const authContext = useContext(AuthContext);
  const { isAuthenticated, user } = authContext;
  console.log('authte rou')
  return (
    <Route
      {...rest}
      render={(props) => {
        return user && isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />;
      }}
    />
  );
};
