import React, { useReducer } from 'react';
import AuthContext from './authContext';
import AuthReducer from './authReducer';

import { toast } from 'react-toastify';


import { updateUser } from 'services/authService/login';

// eslint-disable-next-line
import { LOGIN_SUCCESS, LOGOUT, UPDATE_USER } from '../types';

const AuthState = (props) => {
  const cachedUser = localStorage && JSON?.parse(localStorage.getItem('user'));
  const cachedToken = localStorage && localStorage.getItem('token');
  const cachedAuthorization = localStorage.user && localStorage.token;

  const initialState = {
    isAuthenticated: cachedAuthorization ? true : false,
    user: cachedUser ? cachedUser : null,
    token: cachedToken ? cachedToken : null,
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);

  //login user
  const logIn = async (response) => {
    dispatch({
      type: LOGIN_SUCCESS,
      payload: response,
    });
  };


  //Update User
  const updateUserRecord = async (user) => {
    try {
       let res =  await updateUser(user);
        console.log('res', res);
        
      toast.error('Details Added');
    //   dispatch({
    //     type: UPDATE_USER,
    //     payload: project,
    //   });
    } catch (err) {
        console.log('err',err)
      toast.error('Error updating details');
    }
  };

  //logout
  const logOut = () => {
    dispatch({
      type: LOGOUT,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        isAuthenticated: state.isAuthenticated,
        user: state.user,
        updateUserRecord,
        logIn,
        logOut,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
