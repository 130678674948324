import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ProtectedRouteAdmin from './routes/ProtectedRoute/ProtectedRouteAdmin';
import ProtectedRouteTakers from './routes/ProtectedRoute/ProtectedRouteTakers';
import ProtectedRouteModerator from './routes/ProtectedRoute/ProtectedRouteModerator';
import { PublicRoute } from './routes/ProtectedRoute/PublicRoute';
import {  AuthenticatedRoute } from './routes/ProtectedRoute/AuthenticatedRoute';
import * as LoadableRoutes from './routes';
import './App.scss';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AuthState from 'context/auth/AuthState';
import ProjectState from 'context/project/projectState';

const App = () => {
  const {
    // LandingPage,
    LandingPage2,
    Reset,
    Recover,
    Login,
    Skills,
    Register,
    Confirm,
    AdminDashBoard,
    ModeratorDashBoard,
    TakersDashBoard,
    NotFound,
  } = LoadableRoutes;

  const history = createBrowserHistory();

  return (
    <AuthState>
      <ProjectState>
        <main className="main">
          <ToastContainer autoClose={5000} hideProgressBar={true} newestOnTop={true} />
          <BrowserRouter history={history}>
            <Switch>
              <Route exact path="/" component={LandingPage2} />
              <PublicRoute exact path="/reset/:code" component={Reset} />
              <PublicRoute exact path="/recover" component={Recover} />
              <PublicRoute exact path="/signup/confirmation/:token" component={Confirm} />
              <AuthenticatedRoute exact path="/skills" component={Skills} />
              <PublicRoute exact path="/signup" component={Register} />
              <PublicRoute exact path="/login" component={Login} />
              <ProtectedRouteTakers path="/takers" component={TakersDashBoard} />
              <ProtectedRouteModerator path="/moderator" component={ModeratorDashBoard} />
              <ProtectedRouteAdmin path="/admin" component={AdminDashBoard} />
              <Route path="/404" component={NotFound} />
              <Route component={NotFound} />
            </Switch>
          </BrowserRouter>
        </main>
      </ProjectState>
    </AuthState>
  );
};

export default App;
