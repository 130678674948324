import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import AuthContext from '../../context/auth/authContext';
import roleToRoute from 'utils/roleToRoute';

const ProtectedRouteAdmin = ({ component: Component, UserType, ...rest }) => {
  const authContext = useContext(AuthContext);
  const { isAuthenticated, user } = authContext;
  let token = localStorage.getItem('token') || '';

  let isLoggedIn = false;
  if (token && user.role === 'MODERATOR') {
    isLoggedIn = true;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLoggedIn) {
          return <Component {...rest} {...props} />;
        } else {
          return user && isAuthenticated ? (
            <Redirect to={roleToRoute(user.role)} />
          ) : (
            <Redirect
              to={{
                pathname: `${'/login'}`,
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};

export default ProtectedRouteAdmin;
