const roleToRoute = (role) => {
  let route;
  switch (role) {
    case 'ADMIN':
      route = '/admin';
      break;
    case 'MODERATOR':
      route = '/moderator';
      break;
    case 'TASK_TAKER':
      route = '/takers';
      break;
    default:
      route = '/login';
  }

  return route;
};


export default roleToRoute;