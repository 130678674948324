import { ADD_PROJECT, PROJECT_FAIL } from '../types';

export default (state, action) => {
  switch (action.type) {
    case ADD_PROJECT:
      return {
        ...state,
        projects: [...state.projects, action.payload],
      };
    case PROJECT_FAIL:
      return {
        ...state,
        projectError: action.payload,
      };
    default:
      return state;
  }
};

// import {
//     LOAD_PROJECTS,
//     CLEAR_PROJECTS,
//     ADD_PROJECT,
//     PROJECT_FAIL,
//     DELETE_PROJECT,
//     UPDATE_PROJECT,
//     SET_CURRENT,
//     CLEAR_CURRENT,
//     FILTER_PROJECTS,
//     CLEAR_FILTER
//  } from '../types';

// export default(state,action) => {
//     switch(action.type) {
//         case LOAD_PROJECTS:
//             action.payload.map((PROJECT) => {
//             //     console.log("Api PROJECT is: ",PROJECT );
//                 return state.PROJECTs.push(PROJECT)
//             //     console.log("Inside Staet PROJECT is",state.PROJECTs);
//             //     // state.PROJECTs.map((statePROJECT) => {
//             //     //     if(isEquivalent(statePROJECT,PROJECT)) {
//             //     //         console.log("This item is already inside state")
//             //     //     }else {
//             //     //         console.log("This item is not in state")
//             //     //     }
//             //     // });
//             });
//             return {
//                 ...state
//             }
//         case CLEAR_PROJECTS:
//             state.PROJECTs = [];
//             return {
//                 ...state
//             }
//         case ADD_PROJECT:
//             //state.PROJECTs.push(action.payload);
//             return {
//                 ...state,
//                 PROJECTs : [...state.PROJECTs, action.payload]
//             }
//         case DELETE_PROJECT:
//             return {
//                 ...state,
//                 PROJECTs : state.PROJECTs.filter((PROJECT) => {
//                     return PROJECT._id !== action.payload
//                 })
//         }
//         case UPDATE_PROJECT:
//         return {
//             ...state,
//             PROJECTs:state.PROJECTs.map((PROJECT,index) => {
//                 return PROJECT._id === action.payload._id ? action.payload : PROJECT
//             }),
//             currentSet:false
//          }
//         case SET_CURRENT:
//             return {
//                 ...state,
//                 currentPROJECT: state.PROJECTs.filter((PROJECT) => {
//                     return PROJECT._id === action.payload
//                 }),
//                 currentSet:true
//             }
//         case CLEAR_CURRENT:
//         return {
//             ...state,
//             currentPROJECT:null,
//             currentSet:false
//         }
//         case FILTER_PROJECTS:
//         return {
//             ...state,
//             filtered:state.PROJECTs.filter((PROJECT) => {
//                 const regEx = new RegExp(`${action.payload}`, 'gi');
//                 return PROJECT.name.match(regEx) || PROJECT.email.match(regEx);
//             })
//         }
//         case CLEAR_FILTER:
//             return {
//                 ...state,
//                 filtered:null,
//         }
//         case PROJECT_FAIL:
//             return {
//                ...state,
//                PROJECTErrorFlag:true,
//                PROJECTError:action.payload
//             }
//         default:
//             return state
//     }
// }
