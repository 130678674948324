import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import roleToRoute from 'utils/roleToRoute';
import AuthContext from '../../context/auth/authContext';

export const PublicRoute = ({ component: Component, ...rest }) => {
  const authContext = useContext(AuthContext);
  const { isAuthenticated, user } = authContext;

    return (
      <Route
        {...rest}
        render={(props) => {
          return user && isAuthenticated ? (
            <Redirect to={roleToRoute(user.role)} />
          ) : (
            <Component {...props} />
          );
        }}
      />
    );
};

